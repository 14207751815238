import React, { useState, useCallback, useEffect } from "react";
import {
  FormField,
  UpdateEmployeFormProps,
  CreateEmployeeDto,
} from "../../types";
import CommonForm from "../globalCards/CommonForm";
import Box from "@mui/joy/Box";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";

const UpdateEmployeForm: React.FC<UpdateEmployeFormProps> = ({ data }) => {
  const { showToast } = useAuth();
  const { useUpdateEmployeeMutation, useProjectViewListMutation, error } =
    useApi();
  const [projectID, setProjectID] = useState();
  const [projectName, setProjectName] = useState("");
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    if (useProjectViewListMutation.data) {
      const projectListData = useProjectViewListMutation.data?.map(
        (item: any) => item.name
      );
      setProjectList(projectListData);
    } else if (error) {
      showToast(error, "danger");
    }
  }, [useProjectViewListMutation.data]);

  useEffect(() => {
    if (data.defaultProjectId) {
      const projecName = useProjectViewListMutation.data?.find(
        (item: any) => item.id === data.defaultProjectId
      )?.name;
      setProjectName(projecName);
    }
  }, [data]);
  const removeCountryCode = (phoneNumber: string): string => {
    return phoneNumber.replace(/^\+971\s*/, "").replace(/\s+/g, "");
  };
  const [formData, setFormData] = useState<CreateEmployeeDto>({
    email: data?.email,
    password: data?.password,
    phone: removeCountryCode(data?.phone),
    address: data?.address,
    designation: data?.designation,
    emiratesId: data?.emiratesId,
    empoyeeId: data?.empoyeeId,
    basicSalary: data?.basicSalary,
    dob: data?.dob,
    emiratesExpiryDate: data?.emiratesExpiryDate,
    joiningDate: data?.joiningDate,
    name: data?.name,
    overtimePriceHourly: data?.overtimePriceHourly,
    passportNo: data?.passportNo,
    salaryCertDate: data?.salaryCertDate,
    salaryCertExpiry: data?.salaryCertExpiry,
    defaultProjectName: projectName,
    username: data?.username,
    passportExpiryDate: data?.passportExpiryDate,
  });

  useEffect(() => {
    if (formData.defaultProjectName) {
      const projectID = useProjectViewListMutation.data?.find(
        (item: any) => item.name === formData.defaultProjectName
      )?.id;
      setProjectID(projectID);
      setProjectName(formData.defaultProjectName);
    }
  }, [formData]);

  const handleInputChange = useCallback(
    (name: string, value: string | number) => {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    []
  );
  const toNumber = (value: any) => (isNaN(Number(value)) ? 0 : Number(value));
  const toString = (value: any) => value || "";
  const convertToISOString = (dateString: string): string => {
    const date = new Date(dateString);
    date.setHours(12, 36, 29, 787);

    const isoString = date.toISOString();

    return isoString;
  };

  const convertToFormattedUAEPhoneNumber = (phoneNumber: string): string => {
    let sanitizedPhone = phoneNumber.replace(/\D/g, "");
    if (sanitizedPhone.startsWith("0")) {
      sanitizedPhone = sanitizedPhone.slice(1);
    }
    const formattedUAEPhoneNumber = `+971 ${sanitizedPhone.slice(
      0,
      2
    )} ${sanitizedPhone.slice(2)}`;

    return formattedUAEPhoneNumber;
  };

  const handleSubmit = useCallback(
    (dataUpdate: CreateEmployeeDto) => {
      const transformedData: CreateEmployeeDto = {
        email: toString(dataUpdate.email),
        password: toString(dataUpdate.password),
        phone: toString(convertToFormattedUAEPhoneNumber(dataUpdate.phone)),
        address: toString(dataUpdate.address),
        designation: toString(dataUpdate.designation),
        emiratesId: toString(dataUpdate.emiratesId),
        empoyeeId: toString(dataUpdate.empoyeeId),
        basicSalary: toNumber(dataUpdate.basicSalary),
        dob: convertToISOString(dataUpdate.dob),
        emiratesExpiryDate: convertToISOString(dataUpdate.emiratesExpiryDate),
        passportExpiryDate: convertToISOString(dataUpdate.passportExpiryDate),
        joiningDate: convertToISOString(dataUpdate.joiningDate),
        name: toString(dataUpdate.name),
        overtimePriceHourly: toNumber(dataUpdate.overtimePriceHourly),
        passportNo: toString(dataUpdate.passportNo),
        defaultProjectId: projectID,
        salaryCertDate: convertToISOString(dataUpdate.salaryCertDate),
        salaryCertExpiry: convertToISOString(dataUpdate.salaryCertExpiry),
        username: toString(dataUpdate.username),
      };
      useUpdateEmployeeMutation.mutate({
        id: data.id,
        newData: transformedData,
      });
    },
    [projectID]
  );

  const formFields: FormField[] = [
    {
      name: "empoyeeId",
      label: "Employee ID",
      type: "text",
      value: formData.empoyeeId,
      required: true,
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      required: true,
    },
    {
      name: "designation",
      label: "Designation",
      type: "text",
      value: formData.designation,
      required: true,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      required: true,
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
      value: formData.phone,
      required: true,
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: "date",
      value: formData.dob,
      required: true,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      required: true,
    },
    {
      name: "joiningDate",
      label: "Date Of Joining",
      type: "date",
      value: formData.joiningDate,
      required: true,
    },
    {
      name: "basicSalary",
      label: "Basic Salary",
      type: "number",
      value: formData.basicSalary,
      required: true,
    },
    {
      name: "overtimePriceHourly",
      label: "Overtime Price Hourly",
      type: "number",
      value: formData.overtimePriceHourly,
      required: true,
    },
    {
      name: "passportNo",
      label: "Passport No",
      type: "text",
      value: formData.passportNo,
      required: true,
    },
    {
      name: "passportExpiryDate",
      label: "Passport Expiry Date",
      type: "date",
      value: formData.passportExpiryDate,
      required: true,
    },
    {
      name: "emiratesId",
      label: "Emirates ID",
      type: "text",
      value: formData.emiratesId,
      required: true,
    },
    {
      name: "emiratesExpiryDate",
      label: "Emirates Expiry Date",
      type: "date",
      value: formData.emiratesExpiryDate,
      required: true,
    },
    {
      name: "salaryCertDate",
      label: "Salary Certificate Date",
      type: "date",
      value: formData.salaryCertDate,
      required: true,
    },
    {
      name: "salaryCertExpiry",
      label: "Salary Certificate Expiry",
      type: "date",
      value: formData.salaryCertExpiry,
      required: true,
    },
    {
      name: "defaultProjectName",
      label: "Project's List",
      type: "dropdown",
      default: projectName,
      options: projectList,
      value: formData.defaultProjectName || "",
      required: true,
    },
    {
      name: "username",
      label: "Username",
      type: "text",
      value: formData.username,
      required: true,
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      value: formData.password,
      required: true,
    },
  ];

  const validateForm = (data: CreateEmployeeDto): boolean => {
    return Object.values(data).every((value) => value !== "" && value !== 0);
  };

  const isFormValid = validateForm(formData);
  return (
    <Box sx={{ overflow: "auto", mt: "20px" }}>
      <CommonForm
        fields={formFields}
        onChange={handleInputChange}
        onSubmit={() => handleSubmit(formData)}
        isSubmitDisabled={!isFormValid}
        pagination={true}
      />
    </Box>
  );
};

export default UpdateEmployeForm;
