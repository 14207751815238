import React, { useState, useCallback, useEffect } from "react";
import { CreateEmployeeDto, FormField } from "../../types";
import CommonForm from "../globalCards/CommonForm";
import Box from "@mui/joy/Box";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";
import { useNavigate } from "react-router-dom";

const AddEmployeeCard: React.FC = () => {
  const { showToast } = useAuth();
  const navigate = useNavigate();
  const {
    useAddEmployeeMutation,
    addEmployeeResponse,
    useProjectViewListMutation,
    error,
  } = useApi();
  const [formData, setFormData] = useState<CreateEmployeeDto>({
    email: "",
    password: "",
    phone: "",
    address: "",
    designation: "",
    emiratesId: "",
    empoyeeId: "",
    basicSalary: 0,
    dob: "",
    emiratesExpiryDate: "",
    joiningDate: "",
    name: "",
    overtimePriceHourly: 0,
    passportNo: "",
    salaryCertDate: "",
    salaryCertExpiry: "",
    defaultProjectName: "",
    username: "",
    passportExpiryDate: "",
  });
  const regex = /\(([^)]+)\)/;
  const formatFieldName = (fieldName: string): string => {
    return fieldName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());
  };

  useEffect(() => {
    if (addEmployeeResponse) {
      showToast("Employee created successfully", "success");
      useAddEmployeeMutation.reset();
      navigate("/employee/view");
    } else if (error) {
      const errorMsg = regex.exec(error);
      errorMsg !== null
        ? showToast(`${formatFieldName(errorMsg[1])} already exist`, "danger")
        : showToast(`${formatFieldName(error[0])}`, "danger");
    }
  }, [addEmployeeResponse, error]);

  const handleInputChange = useCallback(
    (name: string, value: string | number) => {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    []
  );
  const toNumber = (value: any) => (isNaN(Number(value)) ? 0 : Number(value));
  const toString = (value: any) => value || "";
  const convertToISOString = (dateString: string): string => {
    const date = new Date(dateString);
    date.setHours(12, 36, 29, 787); // Set hours, minutes, seconds, and milliseconds

    const isoString = date.toISOString();

    return isoString;
  };
  const [projectID, setProjectID] = useState();
  const [projectList, setProjectList] = useState([]);
  useEffect(() => {
    useProjectViewListMutation.mutate();
  }, []);
  useEffect(() => {
    if (useProjectViewListMutation.data) {
      const projectListData = useProjectViewListMutation.data?.map(
        (item: any) => item.name
      );
      setProjectList(projectListData);
    } else if (error) {
      showToast(error, "danger");
    }
  }, [useProjectViewListMutation.data]);

  useEffect(() => {
    if (formData.defaultProjectName) {
      const projectID = useProjectViewListMutation.data?.find(
        (item: any) => item.name === formData.defaultProjectName
      )?.id;
      setProjectID(projectID);
    }
  }, [formData]);

  const convertToFormattedUAEPhoneNumber = (phoneNumber: string): string => {
    let sanitizedPhone = phoneNumber.replace(/\D/g, "");
    if (sanitizedPhone.startsWith("0")) {
      sanitizedPhone = sanitizedPhone.slice(1);
    }
    const formattedUAEPhoneNumber = `+971 ${sanitizedPhone.slice(
      0,
      2
    )} ${sanitizedPhone.slice(2)}`;

    return formattedUAEPhoneNumber;
  };

  const handleSubmit = useCallback(
    (data: CreateEmployeeDto) => {
      const transformedData: CreateEmployeeDto = {
        email: toString(data.email),
        password: toString(data.password),
        phone: toString(convertToFormattedUAEPhoneNumber(data.phone)),
        address: toString(data.address),
        designation: toString(data.designation),
        emiratesId: toString(data.emiratesId),
        empoyeeId: toString(data.empoyeeId),
        basicSalary: toNumber(data.basicSalary),
        dob: convertToISOString(data.dob),
        defaultProjectId: projectID,
        emiratesExpiryDate: convertToISOString(data.emiratesExpiryDate),
        passportExpiryDate: convertToISOString(data.passportExpiryDate),
        joiningDate: convertToISOString(data.joiningDate),
        name: toString(data.name),
        overtimePriceHourly: toNumber(data.overtimePriceHourly),
        passportNo: toString(data.passportNo),
        salaryCertDate: convertToISOString(data.salaryCertDate),
        salaryCertExpiry: convertToISOString(data.salaryCertExpiry),
        username: toString(data.username),
      };
      useAddEmployeeMutation.mutate(transformedData);
    },
    [projectID]
  );

  const formFields: FormField[] = [
    {
      name: "empoyeeId",
      label: "Employee ID",
      type: "text",
      value: formData.empoyeeId,
      required: true,
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      required: true,
    },
    {
      name: "designation",
      label: "Designation",
      type: "text",
      value: formData.designation,
      required: true,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      required: true,
    },
    {
      name: "phone",
      label: "Phone",
      type: "text",
      value: formData.phone,
      required: true,
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: "date",
      value: formData.dob,
      required: true,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      required: true,
    },
    {
      name: "joiningDate",
      label: "Date Of Joining",
      type: "date",
      value: formData.joiningDate,
      required: true,
    },
    {
      name: "basicSalary",
      label: "Basic Salary",
      type: "number",
      value: formData.basicSalary,
      required: true,
    },
    {
      name: "overtimePriceHourly",
      label: "Overtime Price Hourly",
      type: "number",
      value: formData.overtimePriceHourly,
      required: true,
    },
    {
      name: "passportNo",
      label: "Passport No",
      type: "text",
      value: formData.passportNo,
      required: true,
    },
    {
      name: "passportExpiryDate",
      label: "Passport Expiry Date",
      type: "date",
      value: formData.passportExpiryDate,
      required: true,
    },
    {
      name: "emiratesId",
      label: "Emirates ID",
      type: "text",
      value: formData.emiratesId,
      required: true,
    },
    {
      name: "emiratesExpiryDate",
      label: "Emirates Expiry Date",
      type: "date",
      value: formData.emiratesExpiryDate,
      required: true,
    },
    {
      name: "salaryCertDate",
      label: "Salary Certificate Date",
      type: "date",
      value: formData.salaryCertDate,
      required: true,
    },
    {
      name: "salaryCertExpiry",
      label: "Salary Certificate Expiry",
      type: "date",
      value: formData.salaryCertExpiry,
      required: true,
    },
    {
      name: "defaultProjectName",
      label: "Project's List",
      type: "dropdown",
      options: projectList,
      value: formData.defaultProjectName || "",
      required: true,
    },
    {
      name: "username",
      label: "Username",
      type: "text",
      value: formData.username,
      required: true,
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      value: formData.password,
      required: true,
    },
  ];

  const validateForm = (data: CreateEmployeeDto): boolean => {
    return Object.values(data).every((value) => value !== "" && value !== 0);
  };

  const isFormValid = validateForm(formData);
  return (
    <Box sx={{ overflow: "auto", mt: "20px" }}>
      <CommonForm
        fields={formFields}
        onChange={handleInputChange}
        onSubmit={() => handleSubmit(formData)}
        isSubmitDisabled={!isFormValid}
        pagination={true}
      />
    </Box>
  );
};

export default AddEmployeeCard;
