import { useCallback, useEffect } from "react";
import * as XLSX from "xlsx";
import { useApi } from "../../Auth/api/ApiContext";
interface Data {
  name: string;
  presentTotal: string;
  total: string;
  overtimeTotal: string;
  overtimeHours: string;
  holidayTotal: string;
  presentDays: string;
  absentDays: string;
  leaveDays: string;
  halfDays: string;
  holidays: string;
  advanceAmount: string;
}
interface ExportSalaryExcelProps {
  data: Data[];
  fileName: string;
  salaryBulkData: any;
  setSalaryBulkData: (salaryBulkData: any) => void;
}
const ExportSalaryExcel: React.FC<ExportSalaryExcelProps> = ({
  data,
  fileName,
  setSalaryBulkData,
}) => {
  // Convert the array of objects to an array of arrays for Excel
  const { useBulkEmployeeSalaryMutation } = useApi();
  const handleExport = useCallback(() => {
    const excelData = [
      [
        "Name",
        "Total",
        "Advance Amount",
        "Present Days",
        "Present Amount",
        "Absent Days",
        "Leave Days",
        "Half Days",
        "Overtime Hours",
        "Overtime Amount",
        "Holidays",
        "Holiday Amount",
      ], // Headers
      ...data.map((item: any) => [
        item.name,
        item.total,
        item.advanceAmount,
        item.presentDays,
        item.presentTotal,
        item.absentDays,
        item.leaveDays,
        item.halfDays,
        item.overtimeHours,
        item.overtimeTotal,
        item.holidays,
        item.holidayTotal,
      ]),
    ];

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the array to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");

    // Write the workbook to a file
    XLSX.writeFile(workbook, "EmployeeSalaryData.xlsx");
  }, [data, fileName]);
  useEffect(() => {
    if (data.length > 0) {
      handleExport();
      useBulkEmployeeSalaryMutation.reset();
      setSalaryBulkData([]);
    }
  }, [data]);

  // Since we don't need any JSX, return null
  return null;
};
export default ExportSalaryExcel;
