import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { SalaryProps } from "../../types";
import { Modal, ModalDialog } from "@mui/joy";

const SalaryPdf: React.FC<SalaryProps> = ({
  presentTotal,
  total,
  overtimeTotal,
  holidayTotal,
  employee,
  showSalary,
  setShowSalary,
}) => {
  const generatePDF = () => {
    const element = document.getElementById("invoice-box");
    if (element) {
      html2canvas(element)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(`${employee.name}(salary).pdf`);
        })
        .then(() => setShowSalary(false));
    }
  };

  // const calculateTotal = () => {
  //   return items.reduce((total, item) => total + item.price, 0).toFixed(2);
  // };

  return (
    <Modal open={showSalary} onClose={() => setShowSalary(false)}>
      <ModalDialog
        sx={{
          marginLeft: { sm: "5%", xs: "0%" },
          marginTop: { sm: "0%", xs: "5%" },
        }}
      >
        <div className="invoice-box" id="invoice-box">
          <table>
            <thead>
              <tr className="invoice-box-top">
                <td className="title">
                  <span>White Core</span>
                </td>
                <tr className="invoice-information">
                  <td>
                    {employee?.name}({employee?.empoyeeId})
                    <br />
                    {employee?.email}
                    <br />
                    Dubai, UAE
                  </td>
                </tr>
              </tr>

              <tr className="heading">
                <td>Description</td>
                <td>Amount(AED)</td>
              </tr>
            </thead>
            <tbody>
              <tr className="item">
                <td>Basic Salary</td>
                <td>
                  {(Number(presentTotal) + Number(holidayTotal))?.toFixed(2)}
                </td>
              </tr>
              <tr className="item">
                <td>Overtime Salary</td>
                <td>{overtimeTotal?.toFixed(2)}</td>
              </tr>
              <tr className="total">
                <td></td>
                <td>Total(AED): {total?.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
          <button onClick={generatePDF}>Download PDF</button>
        </div>
      </ModalDialog>
    </Modal>
  );
};

export default SalaryPdf;
