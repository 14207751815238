import React from "react";
import "../assets/css/home.css";
import officeImage from "../assets/img/office.jpg";

const Home: React.FC = () => {
  return (
    <div>
      <div className="brand">WHITE CORE</div>
      <div className="address-bar">Dubai, UAE | +971 564696084</div>

      <div className="container">
        <div className="row">
          <div className="box">
            <div className="col-lg-12 text-center">
              <img
                className="img-responsive img-full"
                src={officeImage}
                width="500px"
                height="500px"
                alt="Office"
              />
              <h2 className="brand-before">
                <small>Welcome to</small>
              </h2>
              <h1 className="brand-name">WHITE CORE</h1>
              <hr className="tagline-divider" />
              <h2>
                <small>
                  <strong>High Quality Standards</strong>
                </small>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="box">
            <div className="col-lg-12">
              <hr />
              <h2 className="intro-text text-center">Civil + Interior</h2>
              <hr />
              <hr className="visible-xs" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="box">
            <div className="col-lg-12">
              <hr />
              <h2 className="intro-text text-center">
                Contact
                <strong> WHITE CORE</strong>
              </h2>
              <hr />
            </div>

            <div className="col-lg-12">
              <p>
                Address: <strong>Dubai, UAE</strong>
              </p>
              <p>
                Phone: <strong>+971 564696084</strong> <br />
                Email:{" "}
                <strong>
                  <a href="mailto:info@whitecoreuae.com">
                    info@whitecoreuae.com
                  </a>
                </strong>{" "}
                <br />
                Business Hours: <strong>9:00 AM - 5:00 PM</strong> <br />
              </p>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="copyright">Copyright © WHITE CORE 2023</div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
