import React, { useEffect, useState } from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import BrightnessAutoRoundedIcon from "@mui/icons-material/BrightnessAutoRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BusinessIcon from "@mui/icons-material/Business";
import ColorSchemeToggle from "./ColorSchemeToggle";
import StoreIcon from "@mui/icons-material/Store";
import EmployeeIcon from "@mui/icons-material/Badge";
import { closeSidebar, PermissionDto } from "../../types";
import { useAuth } from "../../Auth/utils/useAuth";
import { Link } from "react-router-dom";
import { useApi } from "../../Auth/api/ApiContext";

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}
interface SidebarProps {
  activeComponent: string;
  setActiveComponent: (component: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  activeComponent,
  setActiveComponent,
}) => {
  const { logout } = useAuth();
  const [permissions, setPermissions] = useState<PermissionDto>();
  const [designation, setDesignation] = useState<string>();
  const { useUserGetMutation, error } = useApi();
  const logoutBtn = () => {
    logout();
  };
  useEffect(() => {
    if (useUserGetMutation) {
      useUserGetMutation.mutate();
    }
  }, []);

  useEffect(() => {
    if (useUserGetMutation.data) {
      setPermissions(useUserGetMutation.data?.permissions);
      setDesignation(useUserGetMutation.data?.designation);
    }
    if (error === "Unauthorized") {
      logoutBtn();
    }
  }, [useUserGetMutation.data, error]);

  const email = localStorage.getItem("email");

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton variant="soft" color="primary" size="sm">
          <BrightnessAutoRoundedIcon />
        </IconButton>
        <Typography level="title-lg">White Core</Typography>
        <ColorSchemeToggle sx={{ ml: "auto" }} />
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": "30px",
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton component={Link} to="/home">
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Home</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              selected={activeComponent === "Dashboard"}
              onClick={() => setActiveComponent("Dashboard")}
              component={Link}
              to="/dashboard"
            >
              <DashboardRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Dashboard</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          {designation === "ADMIN" && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <BusinessIcon />
                    <ListItemContent>
                      <Typography level="title-sm">Business</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <ListItem sx={{ mt: 0.5 }}>
                    <ListItemButton
                      role="menuitem"
                      selected={activeComponent === "CompanyPage"}
                      onClick={() => setActiveComponent("CompanyPage")}
                      component={Link}
                      to="/company/list"
                    >
                      Company
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      role="menuitem"
                      selected={activeComponent === "ProjectsPage"}
                      onClick={() => setActiveComponent("ProjectsPage")}
                      component={Link}
                      to="/projects/list"
                    >
                      Projects
                    </ListItemButton>
                  </ListItem>
                </List>
              </Toggler>
            </ListItem>
          )}
          {permissions?.employeeRead && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <EmployeeIcon />
                    <ListItemContent>
                      <Typography level="title-sm">Employee</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  {permissions?.employeeCreate && (
                    <ListItem sx={{ mt: 0.5 }}>
                      <ListItemButton
                        role="menuitem"
                        selected={activeComponent === "AddEmployee"}
                        onClick={() => setActiveComponent("AddEmployee")}
                        component={Link}
                        to="/employee/add"
                      >
                        Add Employee
                      </ListItemButton>
                    </ListItem>
                  )}
                  {permissions?.employeeRead && (
                    <ListItem>
                      <ListItemButton
                        role="menuitem"
                        selected={activeComponent === "EmployeeDetail"}
                        onClick={() => setActiveComponent("EmployeeDetail")}
                        component={Link}
                        to="/employee/view"
                      >
                        View
                      </ListItemButton>
                    </ListItem>
                  )}
                  {permissions?.employeeRead && (
                    <ListItem>
                      <ListItemButton
                        role="menuitem"
                        selected={activeComponent === "EmployeeSalary"}
                        onClick={() => setActiveComponent("EmployeeSalary")}
                        component={Link}
                        to="/employee/salary"
                      >
                        Employee Salary
                      </ListItemButton>
                    </ListItem>
                  )}
                  {permissions?.employeeRead && (
                    <ListItem>
                      <ListItemButton
                        role="menuitem"
                        selected={activeComponent === "ViewStatement"}
                        onClick={() => setActiveComponent("ViewStatement")}
                        component={Link}
                        to="/employee/statement"
                      >
                        Statement
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </Toggler>
            </ListItem>
          )}
          {permissions?.attendanceRead && (
            <ListItem>
              <ListItemButton
                selected={activeComponent === "Attendance"}
                onClick={() => setActiveComponent("Attendance")}
                component={Link}
                to="/attendance"
              >
                <PersonAddAltRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Attendance</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}
          {permissions?.calendarManage && (
            <ListItem>
              <ListItemButton
                selected={activeComponent === "HolidayCalendar"}
                onClick={() => setActiveComponent("HolidayCalendar")}
                component={Link}
                to="/holiday/calendar"
              >
                <CalendarMonthRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Holiday Calendar</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <ApartmentIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Sales</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton>Customer</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Quotes</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Invoices</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Credit Notes</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <StoreIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Purchase</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton>Vendors</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Expense</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Purchase Order</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Vendor Credit</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Vat</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton>Payment Received</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Payment Made</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Vat Report</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          {designation === "ADMIN" && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <GroupRoundedIcon />
                    <ListItemContent>
                      <Typography level="title-sm">Users</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={{ transform: open ? "rotate(180deg)" : "none" }}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <ListItem>
                    <ListItemButton
                      role="menuitem"
                      selected={activeComponent === "CreateForeman"}
                      onClick={() => setActiveComponent("CreateForeman")}
                      component={Link}
                      to="/user/add"
                    >
                      Create User
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      role="menuitem"
                      selected={activeComponent === "UsersDetails"}
                      onClick={() => setActiveComponent("UsersDetails")}
                      component={Link}
                      to="/user/view"
                    >
                      Users Details
                    </ListItemButton>
                  </ListItem>
                </List>
              </Toggler>
            </ListItem>
          )}
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar
          variant="outlined"
          size="sm"
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{email?.split("@")[0]}</Typography>
          <Typography level="body-xs">{email}</Typography>
        </Box>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          sx={{ marginTop: "-22px" }}
          onClick={logoutBtn}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
};

export default Sidebar;
