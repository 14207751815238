// src/components/AuthenticatedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./useAuth";

interface AuthenticatedRouteProps {
  children: React.ReactNode;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

export default AuthenticatedRoute;
