import React, { useEffect, useState } from "react";
import { useAuth } from "../../Auth/utils/useAuth";
import { useApi } from "../../Auth/api/ApiContext";
import { Link, useLocation } from "react-router-dom";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import ResetIcon from "@mui/icons-material/RotateLeft";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import DayMode from "../../assets/img/dubai-day.jpg";
import NightMode from "../../assets/img/dubai-night.jpg";
import { useMediaQuery } from "@mui/material";

interface FormElements extends HTMLFormControlsCollection {
  password: HTMLInputElement;
  confirmPassword: HTMLInputElement;
}
interface ForgetPasswordFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === "light" ? "dark" : "light");
        onClick?.(event);
      }}
      {...other}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}
const PasswordReset: React.FC = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { showToast, login } = useAuth();
  const { useResetPasswordMutation, resetPasswordResponse, error } = useApi();
  const [token, setToken] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const email = decodeURIComponent(params.get("email") || "");

    if (token && email) {
      setToken(token);
      setEmail(email);
    }
  }, [location]);

  useEffect(() => {
    if (resetPasswordResponse) {
      login(resetPasswordResponse?.token);
    } else if (error) {
      showToast(error, "danger");
    }
  }, [resetPasswordResponse, error]);

  const handleResetPass = (
    event: React.FormEvent<ForgetPasswordFormElement>
  ) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    if (formElements.password.value === formElements.confirmPassword.value) {
      useResetPasswordMutation.mutate({
        email: email,
        newPassword: formElements.password.value,
        token: token,
      });
    } else {
      showToast("Passwords do not match", "danger");
    }
  };

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: "100%", md: "50vw" },
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <IconButton variant="soft" color="primary" size="sm">
                <BadgeRoundedIcon />
              </IconButton>
              <Typography level="title-lg">White Core</Typography>
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Reset Password
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form onSubmit={handleResetPass}>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>
                <FormControl>
                  <FormLabel>Confirm Password</FormLabel>
                  <Input type="password" name="confirmPassword" />
                </FormControl>

                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button type="submit" fullWidth>
                    <ResetIcon fontSize="medium" sx={{ marginRight: "6px" }} />
                    Reset Password
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © White Core {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${DayMode})`,
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage: `url(${NightMode})`,
          },
        })}
      />
    </CssVarsProvider>
  );
};

export default PasswordReset;
