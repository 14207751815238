import React, { useEffect } from "react";
import Button from "@mui/joy/Button";
import Snackbar from "@mui/joy/Snackbar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CloseIcon from "@mui/icons-material/Close";
import { useApi } from "../../Auth/api/ApiContext";

type ToastCardProps = {
  message: string;
  colour: any;
  open: boolean;
  onClose: () => void;
};

const ToastCard: React.FC<ToastCardProps> = ({
  message,
  colour,
  open,
  onClose,
}) => {
  const { error, setError } = useApi();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const filterIcon = (value: any) => {
    switch (value) {
      case "success":
        return <CheckCircleIcon />;
      case "info":
        return <InfoOutlinedIcon />;
      case "danger":
        return <DangerousIcon />;
      case "warning":
        return <WarningAmberIcon />;
      case "error":
        return <ErrorOutlineIcon />;
      default:
        return <InfoOutlinedIcon />;
    }
  };

  return (
    <Snackbar
      variant="soft"
      color={colour}
      autoHideDuration={2000}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      startDecorator={filterIcon(colour)}
      endDecorator={
        <Button onClick={onClose} size="sm" variant="soft" color={colour}>
          <CloseIcon fontSize="small" />
        </Button>
      }
    >
      {message}
    </Snackbar>
  );
};

export default ToastCard;
