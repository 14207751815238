import React, { useState, useMemo } from "react";
import { CommonFormProps, FormField } from "../../types";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Typography,
  IconButton,
  Tooltip,
  Select,
  Option,
} from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";

const CommonForm: React.FC<CommonFormProps> = ({
  fields,
  pagination,
  onSubmit,
  onChange,
  isSubmitDisabled,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [currentPage, setCurrentPage] = useState(0);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>(
    {}
  );
  const fieldsPerPage = useMemo(() => (isMobile ? 5 : 10), [isMobile]);
  const totalPages = useMemo(
    () => Math.ceil(fields.length / fieldsPerPage),
    [fields.length, fieldsPerPage]
  );

  const validateField = (field: FormField): string => {
    if (field.required && !field.value) return "This field is required.";
    if (field.type === "email" && field.value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(field.value as string))
        return "Please enter a valid email address.";
    }
    if (field.type === "date" && field.value) {
      const date = new Date(field.value as string);
      if (isNaN(date.getTime())) return "Please enter a valid date.";
    }
    return "";
  };

  const validateCurrentFields = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    const currentFields = getCurrentFields();

    let hasError = false;
    currentFields.forEach((field) => {
      const error = validateField(field);
      if (error) {
        hasError = true;
        newErrors[field.name] = error;
      }
    });

    setErrors(newErrors);
    return !hasError;
  };

  const getCurrentFields = () => {
    return fields.slice(
      currentPage * fieldsPerPage,
      (currentPage + 1) * fieldsPerPage
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateCurrentFields()) {
      const formData = getCurrentFields().reduce(
        (acc, field) => ({ ...acc, [field.name]: field.value }),
        {}
      );
      onSubmit(formData);
    }
  };

  const handleNext = () => {
    if (validateCurrentFields()) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => setCurrentPage((prevPage) => prevPage - 1);

  const toggleShowPassword = (fieldName: string) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [fieldName]: !prevShowPassword[fieldName],
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select(); // Selects the input value when focused
  };
  const handleChange = (name: string, value: string) => {
    console.log(name, value);
    onChange(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    // Auto-fill username if the changed field is email
    if (name === "email") {
      const usernameField = fields.find((field) => field.name === "username");
      if (usernameField) {
        const username = value.split("@")[0];
        onChange("username", username);
      }
    }
  };

  const currentFields = useMemo(getCurrentFields, [currentPage, fields]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: isMobile ? 0 : 2 }}>
        {currentFields.map((field) => (
          <FormControl
            key={field.name}
            required={field.required}
            sx={{
              width: {
                xs: "100%",
                sm: "48%",
              },
              marginBottom: "16px",
            }}
          >
            <FormLabel>
              {field.label}
              {errors[field.name] && (
                <Typography
                  color="danger"
                  sx={{ fontSize: "10px", marginLeft: "4px" }}
                >
                  ({errors[field.name]})
                </Typography>
              )}
            </FormLabel>

            <Box sx={{ position: "relative" }}>
              {field.type === "dropdown" && field.options ? (
                <Select
                  name={field.name}
                  value={String(field.value)}
                  placeholder={field.default}
                  onChange={(
                    event: React.SyntheticEvent | null,
                    value: string | null
                  ) => {
                    handleChange(
                      field.name,
                      value !== null && value !== undefined
                        ? String(value)
                        : field.default || ""
                    );
                  }}
                  size="sm"
                >
                  {field.options.map((option, idx) => (
                    <Option key={idx} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input
                  name={field.name}
                  type={
                    field.type === "password" && showPassword[field.name]
                      ? "text"
                      : field.type
                  }
                  value={
                    field.type === "date" && field.value
                      ? new Date(field.value).toISOString().split("T")[0]
                      : field.value
                  }
                  onChange={(e) => handleChange(field.name, e.target.value)}
                  onFocus={handleFocus}
                  sx={{
                    pr: field.type === "password" ? "40px" : undefined,
                  }}
                />
              )}
              {field.type === "password" && (
                <IconButton
                  onClick={() => toggleShowPassword(field.name)}
                  sx={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showPassword[field.name] ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              )}
            </Box>
          </FormControl>
        ))}
      </Box>
      {pagination && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          {currentPage > 0 && (
            <Button type="button" variant="solid" onClick={handlePrevious}>
              <NavigateBefore sx={{ mt: "2px" }} />
              Previous
            </Button>
          )}
          {currentPage < totalPages - 1 ? (
            <Button type="button" variant="solid" onClick={handleNext}>
              Next <NavigateNext sx={{ mt: "2px" }} />
            </Button>
          ) : (
            <Tooltip
              title={isSubmitDisabled ? "Please fill all the fields" : ""}
              color="neutral"
              placement="top"
              variant="soft"
              arrow
            >
              <span
                style={{ cursor: isSubmitDisabled ? "not-allowed" : "pointer" }}
              >
                <Button
                  type="submit"
                  variant="solid"
                  disabled={isSubmitDisabled}
                >
                  Submit
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CommonForm;
