// src/components/UnauthenticatedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./useAuth";

interface UnauthenticatedRouteProps {
  children: React.ReactNode;
}

const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? <>{children}</> : <Navigate to="/dashboard" />;
};

export default UnauthenticatedRoute;
