import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";
import { CreateUserFormElement, CompanyDto } from "../../types";
import { IconButton, Table, Tooltip } from "@mui/joy";
import { EditFormModal } from "../globalCards/ModalBox";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CompanyCard() {
  const {
    useAddCompanyMutation,
    useCompanyViewListMutation,
    useUpdateCompanyMutation,
    error,
  } = useApi();
  const [visibleColumns] = useState([
    {
      checked: true,
      width: 140,
      name: "Company Name",
    },
  ]);
  const [rows, setRows] = useState<CompanyDto[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Create");
  const [addCompanyShow, setAddCompanyShow] = useState(false);
  const { showToast } = useAuth();
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (useCompanyViewListMutation) {
      useCompanyViewListMutation.mutate();
    }
  }, []);

  useEffect(() => {
    if (useCompanyViewListMutation.data) {
      setRows(useCompanyViewListMutation.data);
    } else if (error) {
      showToast(error, "danger");
    }

    if (useAddCompanyMutation.data) {
      showToast("Company Create successfully", "success");
      useAddCompanyMutation.reset();
      useCompanyViewListMutation.mutate();
      setAddCompanyShow(false);
    } else if (error) {
      showToast(error, "danger");
    }

    if (useUpdateCompanyMutation.data) {
      showToast("Company updated successfully", "success");
      useUpdateCompanyMutation.reset();
      useCompanyViewListMutation.mutate();
      setAddCompanyShow(false);
    } else if (error) {
      showToast(error, "danger");
    }
  }, [
    useCompanyViewListMutation,
    useAddCompanyMutation.data,
    useUpdateCompanyMutation.data,
    error,
  ]);

  const handleSaveCompanyName = (
    event: React.FormEvent<CreateUserFormElement>
  ) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    if (!formElements.companyName.value) {
      showToast("Please enter company name.", "warning");
      return;
    }
    const companyName = { name: formElements.companyName.value };
    title === "Create"
      ? useAddCompanyMutation.mutate(companyName)
      : useUpdateCompanyMutation.mutate({
          id: companyId,
          newData: { name: formElements.companyName.value },
        });
  };

  const handleAddCompanyBtn = () => {
    setCompanyName("");
    setTitle("Create");
    setAddCompanyShow(true);
  };

  const renderFilters = () => (
    <React.Fragment>
      {/* ----------------Holiday Calendar-------------- */}
      <Button sx={{ height: "20px" }} onClick={handleAddCompanyBtn}>
        <AddIcon />
        Add New Company
      </Button>
    </React.Fragment>
  );

  const handleCellCopy = (content: string | number) => {
    navigator.clipboard
      .writeText(String(content))
      .then(() => {
        showToast("Copied to clipboard!", "success");
      })
      .catch(() => {
        showToast("Failed to copy!", "danger");
      });
  };

  const renderCellContent = (content: string | number, maxWidth: string) => (
    <Tooltip title={content} placement="top">
      <Typography>
        <span
          style={{
            maxWidth,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            cursor: "copy",
          }}
          onClick={() => handleCellCopy(content)}
        >
          {content}
        </span>
      </Typography>
    </Tooltip>
  );

  const handleEditBtn = (usrData: any) => {
    setAddCompanyShow(true);
    setTitle("Edit");
    setCompanyId(usrData.id);
    setCompanyName(usrData.name);
  };

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          marginBottom: "6px",
          alignItems: "flex-end",
          alignSelf: "flex-end",
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        {/* ------------search------------ */}
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              {visibleColumns?.map((item, index) => (
                <th
                  key={index}
                  onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                  style={{
                    display: `${item.checked ? "table-cell" : "block"}`,
                    width: item.width,
                    padding: "12px 6px",
                  }}
                >
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                      },
                    }}
                  >
                    {item.name}
                  </Link>
                </th>
              ))}
              <th style={{ width: 140, padding: "12px 6px", color: "#0b6bcb" }}>
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {useCompanyViewListMutation.isLoading ? (
              <Button loading loadingPosition="start">
                Loading
              </Button>
            ) : (
              stableSort(rows, getComparator(order, "id")).map((row, index) => (
                <tr key={index}>
                  <td>
                    <Typography level="body-xs">
                      {renderCellContent(row?.name, "140")}
                    </Typography>
                  </td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <Link level="body-xs" component="button">
                          <CreateRoundedIcon
                            sx={{
                              color: "coral",
                              fontSize: "20px",
                              borderRadius: "20px",
                              "&:hover": {
                                padding: "2px",
                                backgroundColor: "coral",
                                color: "white",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => handleEditBtn(row)}
                          />
                        </Link>
                      </Tooltip>
                    </Box>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Sheet>
      {/* ---------------Holiday--------------- */}
      <EditFormModal
        open={addCompanyShow}
        setOpen={setAddCompanyShow}
        title={title}
        companyName={companyName}
        companyNameCard={true}
        onSubmit={handleSaveCompanyName}
      />
    </React.Fragment>
  );
}
