import React, { createContext, useContext, useState } from "react";
import { AuthContextType } from "../../types";
import { useApi } from "../api/ApiContext";
import ToastCard from "../../components/globalCards/ToastCard";
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const keys = {
    AUTH_STATE: "s9snolf9smxcvyjgtdbits6sfl&%s5@.sdf",
    TOKEN_KEY: "sjsdf&^%00sd%&.99sdf72#4lsdfisdfkisdlfsd",
  };
  const [toast, setToast] = useState<{
    message: string;
    colour?: any;
    open: boolean;
  }>({ message: "", open: false });

  const showToast = (message: string, colour: any) => {
    setToast({ message, colour, open: true });
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });
  };

  const { useSignInMutation } = useApi();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    () => localStorage.getItem(keys.AUTH_STATE) === "true"
  );

  const login = (token: string) => {
    localStorage.setItem(keys.TOKEN_KEY, token);
    localStorage.setItem(keys.AUTH_STATE, "true");
    localStorage.setItem("email", useSignInMutation.data.email);
    setIsAuthenticated(true);
    showToast("Login successfully", "success");
  };
  const logout = () => {
    useSignInMutation.reset();
    localStorage.setItem(keys.TOKEN_KEY, "");
    localStorage.setItem(keys.AUTH_STATE, "false");
    setIsAuthenticated(false);
    showToast("Logout successfully", "success");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, showToast }}>
      {children}
      <ToastCard
        message={toast.message}
        colour={toast.colour}
        open={toast.open}
        onClose={handleClose}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
