import React, { useEffect, useState } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import Sidebar from "../../components/globalCards/Sidebar";
import Header from "../../components/globalCards/Header";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../Auth/utils/useAuth";
import { useApi } from "../../Auth/api/ApiContext";
import { Navigate, useNavigate } from "react-router-dom";
import { Option, Select } from "@mui/joy";

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  phone: HTMLInputElement;
  password: HTMLInputElement;
}
interface CreateUserFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const CreateUser: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<string>("CreateUser");
  const { showToast } = useAuth();
  const { useCreateUserMutation, createUserResponse, error } = useApi();
  const navigate = useNavigate();
  const [designation, setDesignation] = useState("FOREMAN");
  const regex = /\(([^)]+)\)/;
  const formatFieldName = (fieldName: string): string => {
    return fieldName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());
  };
  useEffect(() => {
    if (createUserResponse) {
      showToast(`User created successfully`, "success");
      useCreateUserMutation.reset();
      navigate("/user/view");
    } else if (error) {
      const errorMsg = regex.exec(error);
      errorMsg !== null
        ? showToast(`${formatFieldName(errorMsg[1])} already exist`, "danger")
        : showToast(`${formatFieldName(error[0])}`, "danger");
    }
  }, [createUserResponse, error]);

  const convertToFormattedUAEPhoneNumber = (phoneNumber: string): string => {
    let sanitizedPhone = phoneNumber.replace(/\D/g, "");
    if (sanitizedPhone.startsWith("0")) {
      sanitizedPhone = sanitizedPhone.slice(1);
    }
    const formattedUAEPhoneNumber = `+971 ${sanitizedPhone.slice(
      0,
      2
    )} ${sanitizedPhone.slice(2)}`;

    return formattedUAEPhoneNumber;
  };
  const handleLogin = (event: React.FormEvent<CreateUserFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    if (
      !formElements.email.value ||
      !formElements.phone.value ||
      !formElements.password.value
    ) {
      showToast("Please fill in all fields.", "warning");
      return;
    }
    useCreateUserMutation.mutate({
      email: formElements.email.value,
      phone: convertToFormattedUAEPhoneNumber(formElements.phone.value),
      designation: designation,
      password: formElements.password.value,
    });
  };

  const handleStatusChange = (
    event: React.SyntheticEvent | null,
    value: string | null
  ) => {
    if (value && setDesignation) {
      setDesignation(value);
    }
  };

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Header />
        <Sidebar
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
        />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
          }}
        >
          <Box>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="/dashboard"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="/user/view"
                fontSize={12}
                fontWeight={500}
              >
                Users
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Create User
              </Typography>
            </Breadcrumbs>
            <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
              Create User
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              borderRadius: "sm",
              flexShrink: 1,
              overflow: "auto",
              minHeight: 0,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Stack
                sx={{
                  display: "flex",
                }}
              >
                <Card>
                  <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">User info</Typography>
                  </Box>
                  <Divider />
                  <form onSubmit={handleLogin}>
                    <Stack
                      direction="row"
                      spacing={3}
                      sx={{
                        display: { xs: "flex", md: "flex" },
                        my: 1,
                        width: { xs: "100%", md: "500px" },
                      }}
                    >
                      <Stack spacing={2} sx={{ flexGrow: 1 }}>
                        <Stack direction="row" spacing={2}>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Email</FormLabel>
                            <Input
                              size="sm"
                              name="email"
                              type="email"
                              startDecorator={<EmailRoundedIcon />}
                              placeholder="Enter Email"
                              sx={{ flexGrow: 1 }}
                            />
                          </FormControl>
                        </Stack>
                        <Stack spacing={1}>
                          <FormLabel>Phone No.</FormLabel>
                          <FormControl
                            sx={{
                              display: { sm: "flex-column", md: "flex-row" },
                              gap: 2,
                            }}
                          >
                            <Input
                              type="number"
                              name="phone"
                              size="sm"
                              startDecorator={<PhoneIcon />}
                              placeholder="Enter Phone No"
                            />
                          </FormControl>
                        </Stack>
                        <Stack spacing={1}>
                          <FormControl size="sm">
                            <FormLabel>Designation</FormLabel>
                            <Select
                              size="sm"
                              defaultValue={
                                designation ? designation : "FOREMAN"
                              }
                              value={designation}
                              name="designation"
                              onChange={handleStatusChange}
                            >
                              <Option value="FOREMAN">FOREMAN</Option>
                              <Option value="ACCOUNTANT">ACCOUNTANT</Option>
                              <Option value="ADMIN">ADMIN</Option>
                            </Select>
                          </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>Password</FormLabel>
                            <Input
                              size="sm"
                              name="password"
                              type="password"
                              startDecorator={<Visibility />}
                              placeholder="password"
                              sx={{ flexGrow: 1 }}
                            />
                          </FormControl>
                        </Stack>
                      </Stack>
                    </Stack>

                    <CardOverflow
                      sx={{ borderTop: "1px solid", borderColor: "divider" }}
                    >
                      <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                        <Button
                          size="sm"
                          variant="solid"
                          type="submit"
                          loading={
                            useCreateUserMutation?.isLoading ? true : false
                          }
                          loadingPosition="end"
                        >
                          Create
                        </Button>
                      </CardActions>
                    </CardOverflow>
                  </form>
                </Card>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
};

export default CreateUser;
