export interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
  showToast: (
    message: string,
    colour?: "success" | "warning" | "danger" | "info" | "error"
  ) => void;
}

export interface ApiContextType {
  getResponse: any;
  postResponse: any;
  deleteResponse: any;
  postMutation: any;
  deleteMutation: any;
}

export interface CreateUserDto {
  email: string;
  password: string;
  phone: string;
}

export interface PermissionDto {
  accountsCreate: boolean;
  accountsDelete: boolean;
  accountsRead: boolean;
  accountsUpdate: boolean;
  attendanceManage: boolean;
  attendanceRead: boolean;
  calendarManage: boolean;
  employeeCreate: boolean;
  employeeDelete: boolean;
  employeeRead: boolean;
  employeeUpdate: boolean;
  permissionsManage: boolean;
  permissionsRead: boolean;
}

export interface UsersListDto {
  id: number;
  email: string;
  phone: string;
  Permissions: PermissionDto[];
}

export interface DeleteModalBoxProps {
  open: boolean;
  message: string;
  setOpen: (open: boolean) => void;
  handleDeleteCnfrm: () => void;
}

export interface UpdateEmployeFormProps {
  data: any;
}
export interface CalendarDto {
  id: number;
  status: string;
  date: string;
  remark: string;
  yearId: number;
  defaultProjectId: number;
  Attendance: [
    {
      id: number;
      state: string;
      createdAt: string;
      updatedAt: string;
      projectId: number;
      dayId: number;
      checkInTime: string;
      checkOutTime: string;
      remark: string;
      employeeId: number;
      updatedById: number;
      createdById: number;
    }
  ];
}

export interface CalendarBoxProps {
  open: boolean;
  data: CalendarDto[];
  title: string;
  currentDate: Date;
  setCurrentDate: (currentDate: Date) => void;
  handleEditBtn: (data: any) => void;
  userId: number;
  projectNameList: ProjectDto[];
  setOpen: (open: boolean) => void;
  handleCloseViewCalendar: () => void;
}

export interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  phone: HTMLInputElement;
  password: HTMLInputElement;
  startDateTime: HTMLInputElement;
  endDateTime: HTMLInputElement;
  attendanceStatus: HTMLInputElement;
  holidayDateTime: HTMLInputElement;
  holidayRemark: HTMLInputElement;
  startDate: HTMLInputElement;
  endDate: HTMLInputElement;
  overTimeStart: HTMLInputElement;
  overTimeEnd: HTMLInputElement;
  remark: HTMLInputElement;
  companyName: HTMLInputElement;
  projectName: HTMLInputElement;
  companyNameSelected: HTMLInputElement;
}

export interface CreateUserFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export interface EditModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  email?: string;
  phone?: string;
  password?: string;
  startDateTimeCard?: boolean;
  endDateTimeCard?: boolean;
  overTimeStartCard?: boolean;
  overTimeEndCard?: boolean;
  remarkCard?: boolean;
  statusCard?: boolean;
  startDateTime?: string;
  endDateTime?: string;
  holidayDateTime?: string;
  calendarStatus?: string;
  holidayRemark?: string;
  holidayRemarkCard?: boolean;
  holidayStatus?: string;
  startDateCard?: boolean;
  startDate?: string;
  endDateCard?: boolean;
  endDate?: string;
  overTimeStart?: string;
  overTimeEnd?: string;
  companyNameCard?: boolean;
  companyName?: string;
  remark?: string;
  projectName?: string;
  projectNameCard?: boolean;
  attendanceStatus?: string;
  companyNameStatusCard?: boolean;
  companyNameSelected?: string;
  projectNameSelected?: string;
  projectNameStatusCard?: boolean;
  companyNameList?: [];
  projectNameList?: [];
  setSelectedStatus?: (holidayStatus: string) => void;
  setProjectNameSelected?: (projectNameSelected: string) => void;
  setCompanyNameSelected?: (companyNameSelected: string) => void;
  onSubmit: (event: React.FormEvent<CreateUserFormElement>) => void;
}

export interface CreateEmployeeDto {
  name: string;
  designation: string;
  joiningDate: string;
  empoyeeId: string;
  dob: string;
  passportNo: string;
  passportExpiryDate: string;
  salaryCertDate: string;
  salaryCertExpiry: string;
  phone: string;
  address: string;
  emiratesId: string;
  emiratesExpiryDate: string;
  basicSalary: number;
  overtimePriceHourly: number;
  defaultProjectName?: string;
  defaultProjectId?: number;
  email: string;
  username: string;
  password: string;
}

export interface ViewEmployeeDto {
  id: number;
  name: string;
  designation: string;
  joiningDate: string;
  empoyeeId: string;
  dob: string;
  passportNo: string;
  passportExpiryDate: string;
  salaryCertDate: string;
  salaryCertExpiry: string;
  phone: string;
  defaultProjectId: string;
  address: string;
  emiratesId: string;
  emiratesExpiryDate: string;
  basicSalary: number;
  overtimePriceHourly: number;
  email: string;
  username: string;
  password: string;
}

export interface FormField {
  name: string;
  label: string;
  type: any;
  value: string | number;
  required?: boolean;
  default?: string;
  options?: string[];
}

export interface CommonFormProps {
  fields: FormField[];
  onSubmit: (formData: { [key: string]: string | number }) => void;
  onChange: (name: string, value: string | number) => void;
  isSubmitDisabled: boolean;
  pagination: boolean;
}

export interface ViewUserDto {
  id: number;
  phone: string;
  email: string;
  Permissions: PermissionDto[];
}

export interface AttendanceRecord {
  id: number;
  state: string;
  createdAt: string;
  updatedAt: string;
  date: string;
  checkInTime: string;
  checkOutTime: string;
  overtimeCheckIn: string;
  overtimeCheckOut: string;
  remark: string;
  employeeId: number;
  updatedById: number;
  createdById: number;
  projectId: number;
}

export interface SalaryDetailsDto {
  presentTotal: number;
  total: number;
  overtimeTotal: number;
  overtimeHours: number;
  holidayTotal: number;
  presentDays: number;
  absentDays: number;
  leaveDays: number;
  halfDays: number;
  holidays: number;
}

export interface SalaryProps {
  presentTotal: number;
  total: number;
  overtimeTotal: number;
  overtimeHours: number;
  holidayTotal: number;
  presentDays: number;
  absentDays: number;
  leaveDays: number;
  halfDays: number;
  holidays: number;
  employee: ViewEmployeeDto;
  showSalary: boolean;
  setShowSalary: (showSalary: boolean) => void;
  downloadPDf: () => void;
}

export interface HolidayCalndrDto {
  id: number;
  status: string;
  date: string;
  remark: string;
  yearId: number;
}

export interface CompanyDto {
  id: number;
  name: string;
}

export interface ProjectDto {
  id: number;
  name: string;
  active: boolean;
  startDate: string;
  endDate: string;
  companyId: number;
}

export interface ViewUserListDto {
  id: number;
  name: string;
  designation: string;
  joiningDate: string;
  empoyeeId: string;
  dob: string;
  passportNo: string;
  passportExpiryDate: string;
  salaryCertDate: string;
  salaryCertExpiry: string;
  phone: string;
  address: string;
  emiratesId: string;
  emiratesExpiryDate: string;
  basicSalary: number;
  overtimePriceHourly: number;
  email: string;
  username: string;
  password: string;
  defaultProjectId: number;
  Attendance: [AttendanceRecord];
}
export function openSidebar() {
  if (typeof window !== "undefined") {
    document.body.style.overflow = "hidden";
    document.documentElement.style.setProperty("--SideNavigation-slideIn", "1");
  }
}

export function closeSidebar() {
  if (typeof window !== "undefined") {
    document.documentElement.style.removeProperty("--SideNavigation-slideIn");
    document.body.style.removeProperty("overflow");
  }
}

export function toggleSidebar() {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--SideNavigation-slideIn");
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}
