import * as XLSX from "xlsx";
import React, { useCallback, useEffect } from "react";
import { useApi } from "../../Auth/api/ApiContext";

interface Attendance {
  name: string | null;
  basicSalary: number;
  overtimePriceHourly: number;
  id: number;
  state: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  date: string | null;
  checkInTime: string | null;
  checkOutTime: string | null;
  overtimeCheckIn: string | null;
  overtimeCheckOut: string | null;
  remark: string | null;
  employeeId: number;
  updatedById: number;
  createdById: number;
  projectId: number;
}

interface AttendanceData {
  date: string;
  state: string; // Ensure 'state' is defined here
}

interface EmployeeData {
  name: string;
  basicSalary: number;
  overtimePriceHourly: number;
  attendance: AttendanceData[];
  overtime: Record<string, string>;
  totalSalary: number;
}
interface Data {
  date: string;
  Attendance: Attendance[];
}

interface ExportToExcelProps {
  data: Data[];
  fileName: string;
}

const convertTo12Hour = (dateTimeString: string) => {
  const date = new Date(dateTimeString);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};
// Function to calculate overtime hours
const calculateOvertimeHours = (
  checkIn: string | null,
  checkOut: string | null
): number => {
  if (checkIn !== null && checkOut !== null) {
    const checkInTime = convertTo12Hour(checkIn).split(":")[0];
    const checkOutTime = convertTo12Hour(checkOut).split(":")[0];
    const convertTime = Number(checkOutTime) - Number(checkInTime);
    return convertTime;
  }
  return 0;
};

// Excel generation component
const ExportToExcel: React.FC<ExportToExcelProps> = ({ data, fileName }) => {
  const { useDownloadAttendanceMutation } = useApi();
  const handleExport = useCallback(() => {
    const employeesData = data.flatMap((entry) =>
      entry.Attendance?.map((attendance) => {
        const {
          name,
          state,
          basicSalary,
          overtimeCheckIn,
          overtimeCheckOut,
          overtimePriceHourly,
        } = attendance;

        const presentCount = state === "PRESENT" || state === "HOLIDAY" ? 1 : 0;
        const date = new Date(entry.date).toLocaleDateString("en-GB");
        const status = state;
        const overtimeHours = calculateOvertimeHours(
          overtimeCheckIn,
          overtimeCheckOut
        );
        const totalSalary = basicSalary;

        return {
          name,
          date,
          status,
          basicSalary: Number(basicSalary),
          overtimeHours: `${overtimeHours} hr`,
          overtimePriceHourly: Number(overtimePriceHourly),
          presentCount: presentCount,
          totalSalary: Number(totalSalary),
        };
      })
    );

    if (employeesData) {
      // Group data by employee name
      const groupedData: Record<string, EmployeeData> = employeesData.reduce(
        (acc: Record<string, EmployeeData>, curr) => {
          const {
            name,
            date,
            basicSalary,
            overtimePriceHourly,
            overtimeHours,
            status,
            totalSalary,
          } = curr;

          if (name !== null) {
            if (!acc[name]) {
              acc[name] = {
                name: name,
                basicSalary: basicSalary,
                overtimePriceHourly: overtimePriceHourly,
                attendance: [],
                overtime: {}, // Initialize overtime as an object
                totalSalary,
              };
            }

            acc[name].attendance.push({
              date,
              state:
                status === "PRESENT"
                  ? "P"
                  : status === "HOLIDAY"
                  ? "H"
                  : status === "ABSENT"
                  ? "A"
                  : "L",
            });

            acc[name].overtime[date] = overtimeHours; // Store overtime with the date as the key
            acc[name].totalSalary = totalSalary;
          }
          return acc;
        },
        {} // Initialize accumulator as an empty object of type Record<string, EmployeeData>
      );

      // Extract unique dates
      const dates = Array.from(new Set(employeesData.map((d) => d.date)));

      // Generate the table data
      const sheetData = [];

      // Push headers
      const headers = [
        "Employee Name",
        ...dates,
        "Basic Salary",
        "OverTime",
        "Amount",
      ];
      sheetData.push(headers);

      // Add data for each employee
      for (const employee in groupedData) {
        const employeeData = groupedData[employee];
        const attendanceRow = [
          employeeData.name,
          ...dates.map(
            (date) =>
              employeeData.attendance.find((att) => att.date === date)?.state ||
              "A"
          ), // Display 'P' for present, otherwise empty
          employeeData.basicSalary,
          "",
          employeeData.attendance.filter(
            (item) => item.state === "P" || item.state === "H"
          ).length * employeeData.totalSalary,
        ];
        sheetData.push(attendanceRow);

        const overtimeRow = [
          "Overtime (hours)",
          ...dates.map((date) => employeeData.overtime[date] || "0 hr"), // Display overtime hours or '0 hr'
          "",
          employeeData.overtimePriceHourly, // Calculate overtime salary
          dates.reduce((acc, date) => {
            const overtimeValue = employeeData.overtime[date]
              ? Number(employeeData.overtime[date].replace(" hr", "")) *
                employeeData.overtimePriceHourly
              : 0;
            return acc + overtimeValue;
          }, 0), // Calculate overtime salary with hours
        ];
        sheetData.push(overtimeRow);
      }
      const finalTotalRow = [
        "",
        ...Array(dates.length).fill(""), // Empty cells for dates
        "",
        "Total",
        sheetData
          .map((row) => row[row.length - 1])
          .filter((value): value is number => typeof value === "number")
          .reduce((sum, value) => sum + value, 0),
      ];
      sheetData.push(finalTotalRow);
      // Generate the worksheet from the sheetData
      const ws = XLSX.utils.aoa_to_sheet(sheetData);

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Attendance");

      // Write the workbook to an Excel file
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    }
  }, [data, fileName]);

  //Trigger export when data changes and is not empty
  useEffect(() => {
    if (data.length > 0) {
      handleExport();
      useDownloadAttendanceMutation.reset();
    }
  }, [data]);

  // Since we don't need any JSX, return null
  return null;
  // return <button onClick={handleExport}>Export to Excel</button>;
};

export default ExportToExcel;
