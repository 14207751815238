import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_PUBLIC_URL}/v1`,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(
      "sjsdf&^%00sd%&.99sdf72#4lsdfisdfkisdlfsd"
    ); // Assume token is stored in localStorage
    if (token && !config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getData = async (endpoint: string) => {
  const response = await api.get(endpoint);
  return response.data;
};

export const postData = async (endpoint: string, data: any) => {
  const response = await api.post(endpoint, data);
  return response.data;
};

export const patchData = async (endpoint: string, data: any) => {
  const response = await api.patch(endpoint, data);
  return response.data;
};

export const deleteData = async (endpoint: string) => {
  const response = await api.delete(endpoint);
  return response.data;
};
