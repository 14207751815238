import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";
import { ViewEmployeeDto } from "../../types";
import { Tooltip, useColorScheme } from "@mui/joy";
import SalaryPdf from "./SalaryPdf";
import ExportToExcel from "../globalCards/ExportToExcel";
import ExportSalaryExcel from "./ExportSalaryExcel";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EmployeeSalaryCard() {
  const {
    useViewEmployeeListMutation,
    useDateVisAttendaceEmployee,
    useHolidayCalendarViewMutation,
    useEmployeeSalaryMutation,
    useBulkEmployeeSalaryMutation,
    error,
  } = useApi();
  const [visibleColumns, setVisibleColumns] = useState([
    {
      checked: true,
      width: 60,
      name: "ID",
    },
    {
      checked: true,
      width: 140,
      name: "Name",
    },
    {
      checked: true,
      width: 140,
      name: "Email",
    },
    {
      checked: true,
      width: 140,
      name: "Attendance Sheet",
    },
    {
      checked: true,
      width: 140,
      name: "Download",
    },
  ]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [invoiceData, setInvoiceDate] = useState<any>([]);
  const [salaryBulkData, setSalaryBulkData] = useState<any>([]);
  const [holidayDates, setHolidayDates] = useState<string[] | undefined>(
    undefined
  );
  const [rows, setRows] = useState<ViewEmployeeDto[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [selected, setSelected] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const { showToast } = useAuth();
  const [userId, setUserId] = useState<number>(0);
  const setTodayDateToFirstOfMonth = (value: Date): string => {
    const date = new Date(value);
    date.setDate(1);
    return date.toISOString();
  };

  const [todayDate, setTodayDate] = useState<string>(
    setTodayDateToFirstOfMonth(new Date())
  );
  const [showSalary, setShowSalary] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1).toISOString();
  const endOfYear = new Date(currentDate.getFullYear() + 1, 0, 0).toISOString();
  const getHolidayData = {
    startDate: startOfYear,
    endDate: endOfYear,
    status: "HOLIDAY",
  };
  useEffect(() => {
    useViewEmployeeListMutation.mutate();
    useHolidayCalendarViewMutation.mutate(getHolidayData);
  }, []);

  useEffect(() => {
    if (useViewEmployeeListMutation.data) {
      setRows(useViewEmployeeListMutation.data);
    }
    if (useEmployeeSalaryMutation.data) {
      setInvoiceDate(useEmployeeSalaryMutation.data[0]);
    }
    if (useBulkEmployeeSalaryMutation.data) {
      const filterData = useBulkEmployeeSalaryMutation.data?.map(
        (item: any) => ({
          presentTotal: item.presentTotal,
          total: item.total,
          overtimeTotal: item.overtimeTotal,
          overtimeHours: item.overtimeHours,
          holidayTotal: item.holidayTotal,
          presentDays: item.presentDays,
          absentDays: item.absentDays,
          leaveDays: item.leaveDays,
          halfDays: item.halfDays,
          holidays: item.holidays,
          name: item.employee.name,
          advanceAmount: item.employee.advanceAmount,
        })
      );
      setSalaryBulkData(filterData);
    }
    if (useDateVisAttendaceEmployee.data) {
      const filterData = useDateVisAttendaceEmployee.data?.map((item: any) => {
        const updatedAttendance = item.Attendance?.map((value: any) => {
          const matchingRow = rows?.find(
            (rowData: any) => value.employeeId === rowData.id
          );
          if (matchingRow) {
            return {
              ...value,
              state: holidayDates?.includes(item?.date)
                ? "HOLIDAY"
                : value?.state,
              basicSalary: matchingRow.basicSalary,
              overtimePriceHourly: matchingRow.overtimePriceHourly,
              name: matchingRow.name,
              username: matchingRow.username,
            };
          }
          return value;
        });
        return {
          ...item,
          Attendance: updatedAttendance,
        };
      });
      setAttendanceData(filterData);
    }
    if (useHolidayCalendarViewMutation.data) {
      const holidayDates = useHolidayCalendarViewMutation.data;
      const filterData = holidayDates
        .filter((holiday: { status: string }) => holiday.status === "HOLIDAY")
        .map((holiday: { date: string }) => holiday.date);
      setHolidayDates(filterData);
    }
    if (error) {
      showToast(error, "danger");
    }
  }, [
    useViewEmployeeListMutation.data,
    useEmployeeSalaryMutation.data,
    useBulkEmployeeSalaryMutation.data,
    useDateVisAttendaceEmployee.data,
    error,
  ]);

  const handleCheckboxChange = (columnName: string) => {
    setVisibleColumns((prevColumns) =>
      prevColumns?.map((column) =>
        column.name === columnName
          ? { ...column, checked: !column.checked }
          : column
      )
    );
  };

  const handleSelectDate = (e: any) => {
    const { name, value } = e.target;
    if (name === "selectStartDate") {
      setTodayDate(new Date(value).toISOString());
    } else if (name === "selectEndDate") {
      setEndDate(new Date(value).toISOString());
    }
  };
  const handleExcelDownload = (id: number) => {
    const getEmployeInfo = {
      id: id,
      startDate: todayDate,
      endDate: endDate,
    };
    if (id) {
      useDateVisAttendaceEmployee.mutate(getEmployeInfo);
    }
  };
  const handleDownload = (data: any) => {
    setUserId(data.id);
    if (data.id) {
      useEmployeeSalaryMutation.mutate({
        id: String([data.id]),
        startDate: todayDate,
        endDate: endDate,
      });
    }
    setShowSalary(true);
  };
  const handleAllSalaryDownload = () => {
    const filterId = rows?.map((obj) => obj.id);
    if (filterId) {
      useBulkEmployeeSalaryMutation.mutate({
        id: String([filterId]),
        startDate: todayDate,
        endDate: endDate,
      });
    }
  };
  const renderFilters = () => (
    <React.Fragment>
      {/* ----------------Select Date-------------- */}
      <FormControl>
        <FormLabel sx={{ marginBottom: "0px" }}>Start Date</FormLabel>
        <Input
          name="selectStartDate"
          sx={{ fontSize: "14px" }}
          type="date"
          defaultValue={new Date(todayDate).toISOString().split("T")[0]}
          onChange={handleSelectDate}
        />
      </FormControl>
      <FormControl>
        <FormLabel sx={{ marginBottom: "0px" }}>End Date</FormLabel>
        <Input
          name="selectEndDate"
          sx={{ fontSize: "14px" }}
          type="date"
          defaultValue={new Date(endDate).toISOString().split("T")[0]}
          onChange={handleSelectDate}
        />
      </FormControl>
      {/* ----------------columns-------------- */}
      <Box>
        <FormLabel>Columns</FormLabel>
        <Select size="sm" placeholder="All">
          {visibleColumns?.map((column) => (
            <Option key={column.name} value={column.name}>
              <Checkbox
                label={column.name}
                checked={column.checked}
                onChange={() => handleCheckboxChange(column.name)}
              />
            </Option>
          ))}
        </Select>
      </Box>
      {/* ----------------Download Salary-------------- */}
      <Button
        sx={{ height: "22px", marginTop: "16px", alignSelf: "flex-end" }}
        loading={useBulkEmployeeSalaryMutation.isLoading}
        loadingPosition="end"
        onClick={handleAllSalaryDownload}
      >
        Download Salary
      </Button>
    </React.Fragment>
  );

  const handleCellCopy = (content: string | number) => {
    navigator.clipboard
      .writeText(String(content))
      .then(() => {
        showToast("Copied to clipboard!", "success");
      })
      .catch(() => {
        showToast("Failed to copy!", "danger");
      });
  };

  const handleSearchBtn = (event: any) => {
    const { value } = event.target;
    if (value.length > 0) {
      setRows((prvData) =>
        prvData.filter(
          (item) =>
            item.name?.toLowerCase().includes(value.toLowerCase()) ||
            item.email?.toLowerCase().includes(value.toLowerCase()) ||
            item.designation?.toLowerCase().includes(value.toLowerCase()) ||
            item.username?.toLowerCase().includes(value.toLowerCase()) ||
            item.phone?.includes(value)
        )
      );
    } else {
      setRows(useViewEmployeeListMutation.data);
    }
  };

  const renderCellContent = (content: string | number, maxWidth: string) => (
    <Tooltip title={content} placement="top">
      <Typography>
        <span
          style={{
            maxWidth,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            cursor: "copy",
          }}
          onClick={() => handleCellCopy(content)}
        >
          {content}
        </span>
      </Typography>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        {/* ------------search------------ */}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for employee list</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            onChange={handleSearchBtn}
            sx={{ width: { xs: "100%", md: "40%", sm: "100%" } }}
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              {visibleColumns?.map((item, index) => (
                <th
                  key={index}
                  onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                  style={{
                    display: `${item.checked ? "table-cell" : "block"}`,
                    width: item.width,
                    padding: "12px 6px",
                  }}
                >
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                      },
                    }}
                  >
                    {item.name}
                  </Link>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {useViewEmployeeListMutation.isLoading ? (
              <Button loading loadingPosition="start">
                Loading
              </Button>
            ) : (
              stableSort(rows, getComparator(order, "id")).map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center", width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      color={selected.includes(row.id) ? "primary" : undefined}
                      onChange={(event) => {
                        setSelected((ids) =>
                          event.target.checked
                            ? ids.concat(row.id)
                            : ids.filter((itemId) => itemId !== row.id)
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                      sx={{ verticalAlign: "text-bottom" }}
                    />
                  </td>
                  {visibleColumns[0].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.id, "60")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[1].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.name, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[2].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.email, "140")}
                      </Typography>
                    </td>
                  )}
                  <td>
                    <Box
                      sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      onClick={() => handleExcelDownload(row.id)}
                    >
                      <Link level="body-xs" component="button">
                        <span style={{ color: "#555e68" }}>
                          {useDateVisAttendaceEmployee.isLoading ? (
                            <span>Loading...</span>
                          ) : (
                            <span>Attendace </span>
                          )}
                        </span>
                        (.xsl)
                      </Link>
                    </Box>
                  </td>
                  <td>
                    <Box
                      sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      onClick={() => handleDownload(row)}
                    >
                      <Link level="body-xs" component="button">
                        <span style={{ color: "#555e68" }}>
                          {useEmployeeSalaryMutation.isLoading ? (
                            <span>Loading...</span>
                          ) : (
                            <span>Download </span>
                          )}
                        </span>
                        (.pdf)
                      </Link>
                    </Box>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Sheet>
      {/* -------------Salary Download-------------- */}
      {!useEmployeeSalaryMutation.isLoading && (
        <SalaryPdf
          {...invoiceData}
          downloadPDf={handleDownload}
          showSalary={showSalary}
          setShowSalary={setShowSalary}
        />
      )}
      {/* -------------Excel Sheet Download-------------- */}
      {attendanceData?.length > 0 && (
        <ExportToExcel data={attendanceData} fileName="Employee Data" />
      )}
      {/* -------------Excel Sheet Download Salary-------------- */}
      {salaryBulkData?.length > 0 && (
        <ExportSalaryExcel
          data={salaryBulkData}
          fileName="Employee Salary"
          setSalaryBulkData={setSalaryBulkData}
          salaryBulkData={salaryBulkData}
        />
      )}
      {/* -------------pagination-------------- */}
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: "flex",
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box
          sx={{
            flex: 1,
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        />
        {["1", "2", "3", "…"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box
          sx={{
            flex: 1,
          }}
        />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
